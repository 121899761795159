/**
 * Titel: INIT
 * Beskrivelse: entry point for initialisering af funktioner fra de øvrige scripts - vi bestræber os på kun at have én jQuery(document).ready i "master scriptet"
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

(function () {
    // AU.maps.loadGoogleMapsScript();
    AU.cookies.initCookieScript();

    jQuery(document).ready(function () {

        jQuery(document).foundation(); // Fordi det er fuldstændig utænkeligt, at vi kan få fjernet Prototype fra pure.au.dk...

        if (window.AUCDNPATH) {
            AU.cdn = window.AUCDNPATH;
        }

        AU.bss = document.querySelector('body').classList.contains('aarhus-bss');

        // Vi bruger lang attributten til at afgøre, hvilket sprog vi skal bruge
        var langAttr = document.querySelector('html').getAttribute('lang');
        if (typeof langAttr !== 'undefined' && langAttr.indexOf('da') === -1) {
            AU.pageLang = 'en';
            AU.ipure.source.searchDetailsCrossSite = '//ipure.nfit.au.dk/current/en_GB/lno/';
        }

        var n = AU.navigation;

        n.menu.setClick();
        n.menu.setUtility();
        n.menu.setBreadcrumb();
        n.menu.addExtraMenuItem();
        n.menu.setTopBar();
        if (!AU.bss) {
            n.search.setClick();
        }
        n.frontpage.setTargetGroups();

        AU.alphabox.boxes.push(AU.pageLang === 'da' ? DEFAULT_staff_staffservice_da : DEFAULT_staff_staffservice_en);

        AU.cookies.strings.header = AU.pageLang === 'da' ? 'Hvis du klikker videre på siden, <strong>accepterer du vores brug af cookies</strong>.' : 'By continuing to use this site<strong> you agree to our cookie policy</strong>.';
        AU.cookies.strings.paragraph = AU.pageLang === 'da' ? 'Aarhus Universitet bruger cookies til at udarbejde statistik og i forbindelse med annoncering og services fra sociale medier, som vi benytter. <span class="hide-for-small-only">Klikker du videre på siden, accepterer du, at der sættes cookies til disse formål. <a href="https://www.au.dk/om/profil/cookies/">Læs mere om cookies på au.dk, herunder hvordan du fravælger brugen af cookies</a>.</span><span class="show-for-small-only"><a href="https://www.au.dk/om/profil/cookies/">Læs mere</a>.</span>' : 'Aarhus University uses cookies to collect statistical data and in connection with advertising and services from social media that we use. <span class="hide-for-small-only">By continuing to use this site, you agree to our use of cookies for these purposes. <a href="https://international.au.dk/about/profile/cookies-policy/">Read more about cookies on au.dk and how to block these</a>.</span><span class="show-for-small-only"><a href="https://international.au.dk/about/profile/cookies-policy/">Read more</a>.</span>';
        AU.cookies.strings.button = AU.pageLang === 'da' ? 'Acceptér og luk' : 'Accept and close';

        AU.cookies.init();
        AU.design.setFocusFieldIds();
        AU.design.setBannerElementsReady();
        AU.design.setBuildingLinks('.address p');
        AU.design.setCollapse();
        AU.design.setHero();
        AU.design.setMagnificPopup();
        AU.design.setTopWarning();

        var s = AU.social;
        if (jQuery('.au_news-single-item .addthis_toolbox').length > 0) {
            jQuery('.au_news-single-item').css('position', 'relative');
            s.disableAddThisForOldNews();
            s.sharers.push(s.newsSharer);
        }

        s.init();

        AU.databox.init();
        AU.flowbox.init();
        AU.alphabox.init();
        AU.piechart.init();
        AU.news.feeds = window.newsLists;
        AU.news.init();

        if (window.cludoDanishPath && window.cludoEnglishPath && (typeof window.defaultTracking === 'undefined' || window.defaultTracking)) {
            AU.search.init(AU.pageLang == 'da' ? window.cludoDanishPath : window.cludoEnglishPath);
        }

        var cmForms = jQuery('.js-cm-form');
        cmForms.each(function (i, form) {
            var privacyEmail = jQuery('input[name="cm-privacy-email"]', form);
            if (privacyEmail.length === 0) {
                jQuery(form).append('<input type="checkbox" id="cm-privacy-email" name="cm-privacy-email" style="display: none;" />');
                jQuery(form).append('<input type="hidden" id="cm-privacy-email-hidden" name="cm-privacy-email-hidden" value="true" />');
            }
        });

        // Alle funktioner, som er pushet til ready-objektet, løbes igennem
        for (var i = 0; i < AU.ready.length; i++) {
            if (typeof (AU.ready[i]) === 'function') {
                try {
                    // Attempt to execute the function
                    AU.ready[i]();
                } catch (e) {
                    // Catch any error and log it, but don't stop execution
                    console.error("Error in AU.ready function loop (au-init.js) at index: " + i, e);
                }
            }
        }

        /*
        if (typeof findWidgetInit === 'function') {
            var dropdownWidgetLoaded = false;
            jQuery(document).on('show.zf.dropdown', function () {
                if (!dropdownWidgetLoaded) {
                    findWidgetInit('header .find-container');
                    dropdownWidgetLoaded = true;
                }
            });
        }
        */

        var findBtn = document.querySelector('header .find-button');
        if (findBtn) {
            findBtn.setAttribute('href', AU.pageLang === 'da' ? 'https://www.au.dk/kort/' : 'https://www.au.dk/en/map/');
        }

        if ((typeof AU.basic === 'undefined' || !AU.basic) && (typeof window.defaultTracking === 'undefined' || window.defaultTracking)) {
            // Siteimprove
            (function () {
                var sz = document.createElement('script'); 
                sz.type = 'text/plain'; 
                sz.async = true;
                sz.setAttribute('data-cookiescript', 'accepted');
                sz.setAttribute('data-cookiecategory', 'performance');
                sz.src = '//siteimproveanalytics.com/js/siteanalyze_4573657.js';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sz, s);
            })();
        }
    });

    jQuery(window).on('load', function () {
        AU.design.setBannerElementsLoad();

        for (var i = 0; i < AU.load.length; i++) {
            if (typeof (AU.load[i]) === 'function') {
                AU.load[i]();
            }
        }
    });

    jQuery(window).on('resize', function () {
        for (var i = 0; i < AU.resize.length; i++) {
            if (typeof (AU.resize[i]) === 'function') {
                AU.resize[i]();
            }
        }
    });

    jQuery(window).on('scroll', function () {
        for (var i = 0; i < AU.scroll.length; i++) {
            if (typeof (AU.scroll[i]) === 'function') {
                AU.scroll[i]();
            }
        }
    });
})();
